import React, { useRef, useState } from 'react';
import './FrontPage.scss';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useForm } from 'react-hook-form';
import { addToNewsletter } from './services/FrontPageService';
import ReCAPTCHA from 'react-google-recaptcha';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { NewsletterData } from './models/NewsletterData';
import { useTranslation } from 'react-i18next';

const FrontPage: React.FC = () => {
  const [apiErrorTop, setApiErrorTop] = useState<string | null>(null);
  const {
    register: registerTop,
    handleSubmit: handleSubmitTop,
    formState: { errors: errorsTop },
  } = useForm();

  const { t } = useTranslation();

  const sectionRefs = useRef<HTMLElement[]>([]);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const successDialog = () => {
    confirmDialog({
      message: t('emailSuccessfullySaved'),
      header: t('dataSaved'),
      defaultFocus: 'accept',
      acceptLabel: 'OK',
      accept: () => window.location.reload(),
    });
  };

  const onSubmit = async (formData: any, isTop: boolean) => {
    const recaptchaText = t('completedCaptcha');
    if (!recaptchaRef.current) {
      setApiErrorTop(recaptchaText);
      return;
    }

    const token = await recaptchaRef.current?.executeAsync();
    const data = {
      email: formData.email,
      recaptchaToken: token,
    } as NewsletterData;

    await addToNewsletter(data)
      .then(() => successDialog())
      .catch((err) => {
        setApiErrorTop(err.response?.data.message);
      });
  };

  const clearApiErrorTop = () => setApiErrorTop(null);

  return (
    <>
      <ConfirmDialog className="dialog-box dialog-box--accept" />

      <header className="header" ref={(el) => (sectionRefs.current[1] = el!)}>
        <div className="container" ref={menuRef}>
          <div className="container__left">
            <div className="logo-box">
              <div className="logo-box__logo" />
            </div>
            <div className="header__title-box title-box ">
              <h1 dangerouslySetInnerHTML={{ __html: t('elevateYourBrandVisibility') }} />
              <h3 dangerouslySetInnerHTML={{ __html: t('elevateYourBrandVisibilityDesc') }} />
            </div>
            <div className="header__form-box">
              <h6>{t('comingSoon')}</h6>
              <form onSubmit={handleSubmitTop((data) => onSubmit(data, true))}>
                <InputText
                  type="text"
                  {...registerTop('email', { required: t('emailIsRequired') })}
                  className={errorsTop.email || apiErrorTop ? 'invalid' : ''}
                  placeholder={t('emailAddress')}
                  onInput={clearApiErrorTop}
                />
                {errorsTop.email && <span>{errorsTop.email.message as string}</span>}
                {apiErrorTop && <span>{apiErrorTop}</span>}
                <Button label={t('earlyAccess')} type="submit" />
              </form>
            </div>
          </div>
          <div className="container__right">
            <div className="header__img" />
          </div>
        </div>
      </header>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || 'sample'}
        size="invisible"
      />
    </>
  );
};

export default FrontPage;
