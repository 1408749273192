import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      emailSuccessfullySaved: 'Your email has been successfully saved.',
      dataSaved: 'Data saved',
      completedCaptcha: 'Please complete the reCAPTCHA.',
      elevateYourBrandVisibility: 'Measure<br>Your brand in AI',
      elevateYourBrandVisibilityDesc:
        "Track your brand's recommendations across major AI platforms like ChatGPT, Claude and Gemini.",
      whatIsChat: 'What is Chatbeat?',
      whatIsChatDesc:
        'Chatbeat is an AI-driven platform that automatically monitors and analyzes prompts to give you actionable insights into your brand’s online presence. Track your Share of Voice,measure brand mentions, and see how visible you and your competitors are in real-time.',
      whyChoseUp: 'Why Choose Us?',
      automaticPromptMonitoring: 'Automatic Prompt Monitoring',
      automaticPromptMonitoringDesc:
        "Stay informed with real-time monitoring of prompts that mention your brand. Our AI tracks every mention, so you don't have to.",
      brandMentionsAnalysis: 'Brand Mentions Analysis',
      brandMentionsAnalysisDesc:
        'Understand where, how, and how often your brand is mentioned across various platforms, helping you measure the effectiveness of your communication strategies.',

      shareOfVoiceTracking: 'Share of Voice Tracking',
      shareOfVoiceTrackingDesc:
        'Measure your Share of Voice and compare it with your competitors to see how well your brand stands out in the market.',
      competitorVisibilityInsights: 'Competitor Visibility Insights',
      competitorVisibilityInsightsDesc:
        "Gain a clear understanding of your competitors' visibility and how you compare. Use these insights to sharpen your strategies and stay ahead.",
      howItWorks: 'How it works',
      setUpMonitoring: 'Set Up Monitoring',
      setUpMonitoringDesc:
        'Define the prompts, keywords, and competitors you want to track. Our AI starts monitoring immediately.',
      analyzeData: 'Analyze the Data',
      analyzeDataDesc:
        "Receive real-time reports on brand mentions, Share of Voice, and visibility. Use detailed analytics to understand your brand's position.",
      optimizeYourStrategy: 'Optimize Your Strategy',
      optimizeYourStrategyDesc:
        'Leverage insights to refine your marketing and communication strategies, ensuring your brand remains prominent.',
      readyToTakeControl: "Ready to Take Control of Your Brand's Visibility?",
      readyToTakeControlDesc:
        'Join our beta program and gain insights that will give your brand the competitive edge.',
      frequentlyAskedQueries: 'Frequently Asked Queries',
      frequentlyAskedQueriesDesc:
        'Efficiently productivate reliable paradigms before ubiquitous models. Continually utilize frictionless expertise with tactical relationships. Phosfluorescently incentivize low-risk high-yield opportunities before sticky synergy.',
      firstQuestion: 'What is the benefit of joining the beta?',
      firstQuestionDesc:
        'As a beta user, you’ll get early access to our advanced monitoring tools and provide feedback that will shape the final product.',
      secondQuestion: 'How accurate is the monitoring?',
      secondQuestionDesc:
        'Our AI-driven platform ensures high accuracy by continuously learning and adapting to new data sources and changes in online discussions.',
      thirdQuestion: 'Is there a cost to join the beta?',
      thirdQuestionDesc:
        'No, participation in the beta is free. We value your input as we refine the platform.',
      earlyAccess: 'Early access',
      emailIsRequired: 'Email is required',
      emailAddress: 'Email address',
      whyChooseUs: 'Why choose Us?',
      about: 'O nas',
      joinToBeta: 'Join to beta',
      comingSoon: 'Coming soon',
    },
  },
  pl: {
    translation: {
      emailSuccessfullySaved: 'Twój email został pomyślnie zapisany.',
      dataSaved: 'Dane zapisane',
      completedCaptcha: 'Proszę wypełnij reCAPTCHA.',
      elevateYourBrandVisibility: 'Mierz<br>swoją markę w AI',
      elevateYourBrandVisibilityDesc:
        'Śledź rekomendacje swojej marki na głównych platformach AI, takich jak ChatGPT, Claude i Gemini.',
      whatIsChat: 'Czym jest Chatbeat?',
      whatIsChatDesc:
        'Chatbeat to platforma oparta na sztucznej inteligencji, która automatycznie monitoruje i analizuje zapytania, dostarczając praktycznych informacji na temat obecności Twojej marki w Internecie. Śledź swój udział w rynku, licz wzmianki o marce i sprawdzaj, jak widoczna jest Twoja marka i konkurencja w czasie rzeczywistym.',
      whyChoseUp: 'Dlaczego warto nas wybrać?',
      automaticPromptMonitoring: 'Monitorowanie zapytań',
      automaticPromptMonitoringDesc:
        'Bądź na bieżąco dzięki monitorowaniu zapytań zawierających Twoją markę. Nasza aplikacja śledzi każdą wzmiankę!',
      brandMentionsAnalysis: 'Analiza wzmianek o marce',
      brandMentionsAnalysisDesc:
        'Dowiedz się, gdzie i jak często Twoja marka jest wspominana na różnych platformach, co pomoże Ci mierzyć skuteczność strategii komunikacyjnych.',
      shareOfVoiceTracking: 'Share of Voice',
      shareOfVoiceTrackingDesc:
        'Mierz swój Share Of Voice i porównuj go z konkurencją, aby zobaczyć, jak dobrze Twoja marka wyróżnia się na rynku.',
      competitorVisibilityInsights: 'Analiza konkurencji',
      competitorVisibilityInsightsDesc:
        'Uzyskaj jasny obraz widoczności konkurencji i sprawdź, jak wypadasz na ich tle. Wykorzystaj te informacje, aby doskonalić swoje strategie i być o krok przed innymi.',
      howItWorks: 'Jak to działa',
      setUpMonitoring: 'Ustaw monitorowanie',
      setUpMonitoringDesc:
        'Zdefiniuj zapytania, słowa kluczowe i konkurentów, których chcesz śledzić. Nasza sztuczna inteligencja rozpocznie monitorowanie natychmiast.',
      analyzeData: 'Analizuj dane',
      analyzeDataDesc:
        'Otrzymuj raporty w czasie rzeczywistym dotyczące wzmianek o marce, udziału w głosie i widoczności. Wykorzystaj szczegółowe analizy, aby zrozumieć pozycję swojej marki.',
      optimizeYourStrategy: 'Optymalizuj swoją strategię',
      optimizeYourStrategyDesc:
        'Wykorzystaj zdobyte informacje, aby ulepszyć strategie marketingowe i komunikacyjne, zapewniając swojej marce silną pozycję.',
      readyToTakeControl: 'Gotowy przejąć kontrolę nad widocznością swojej marki?',
      readyToTakeControlDesc:
        'Dołącz do naszego programu beta i zyskaj informacje, które dadzą Twojej marce przewagę nad konkurencją.',
      frequentlyAskedQueries: 'Najczęściej zadawane pytania',
      frequentlyAskedQueriesDesc:
        'Efektywnie wprowadzaj niezawodne schematy przed wszechobecnymi modelami. Stale wykorzystuj płynne podejścia z taktycznymi relacjami. Zachęcaj do niskiego ryzyka i wysokiej wydajności przed tworzeniem synergii.',
      firstQuestion: 'Jakie korzyści płyną z udziału w wersji beta?',
      firstQuestionDesc:
        'Jako użytkownik wersji beta otrzymasz wczesny dostęp do naszych zaawansowanych narzędzi monitorowania i możliwość wpływania na ostateczną wersję produktu.',
      secondQuestion: 'Jak dokładne jest monitorowanie?',
      secondQuestionDesc:
        'Nasza platforma oparta na sztucznej inteligencji zapewnia wysoką dokładność, stale ucząc się i dostosowując do nowych źródeł danych i zmian w dyskusjach online.',
      thirdQuestion: 'Czy udział w wersji beta wiąże się z kosztami?',
      thirdQuestionDesc:
        'Nie, udział w wersji beta jest bezpłatny. Cenimy Twój wkład, pomagając nam dopracować platformę.',
      earlyAccess: 'Wczesny dostęp',
      emailIsRequired: 'Adres email jest wymagany',
      emailAddress: 'Adres email',
      whyChooseUs: 'Dlaczego my?',
      about: 'O nas',
      joinToBeta: 'Dołącz do bety',
      comingSoon: 'Już wkrótce',
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['navigator', 'htmlTag', 'localStorage', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
